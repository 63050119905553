.section--testimonials {
    padding: 6rem 0;

    blockquote {
        @include font-size(1.7rem);
        opacity: 0.8;

        p { 
            font-size: inherit;
        }
    }

    .testimonial-block {
        color: white;
        text-align: left;
        align-items: center;
        display: flex;
        margin: 2rem 0;

        .author {
            @include font-size(1.1rem);
            font-weight: 700;
            display: block;
            margin-bottom: 0.2em;
        }

        .author-meta {
            line-height: 1.2;
            opacity: 0.8;
        }

    }

    .testimonial-image {
        position: relative;
        width: 4rem;
        height: 4rem;
        flex: 0 0 4rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        background-color: rgba(#fff, 0.25);
        
        img {
            position: absolute;
            top: 0;
        }
    }

    .tns-slider {
        transition-timing-function: $easeInOutExpo;

        .slide {
            opacity: 0;
            transition: opacity 0.35s;
        }
        
        .slide.tns-slide-active {
            opacity: 1;
        }
    }

    .tns-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            margin: 10px;
            cursor: pointer;
            width: 12px;
            height: 12px;
            background-color: rgba(#000, 0.75);
            border: 0;
            padding: 0;
            border-radius: 10px;
            transition: 0.25s;
    
            &.tns-nav-active {
                background-color: white;
                transform: scale(1.25);
            }
        }
    }

    
}
