@mixin button-bg($bg) {
    background: $bg;
    &:hover {
        background: darken($bg, 8%);
        transition: all 0.3s ease;
    }
    &:active {
        background: darken($bg, 25%);
    }
}

// button colors
@each $key, $val in $colors {
    .button--#{$key} {
        @include button-bg($val);
    }
}

.button.button--white {
    color: map-get($colors, gray-dark);
}

.button {
    display: inline-block;
    color: white;
    text-decoration: none;
    font-weight: $bold;
    transition: 0.25s;
    backface-visibility: hidden;
    border: none;
    cursor: pointer;
    transform: translate(0, 0) scale(1);

    &.underline {
        box-shadow: none;
        background: transparent;
        text-decoration: underline;
        color: map-get($colors, purple);
    }

    &:hover {
        transform: translate(0, -4px) scale(1);
    }

    &:active {
        transform: scale(0.98);
    }

}
