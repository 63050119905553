.modal-overlay {
    background: rgba(map-get($colors, purple-dark), 0.85);

    .modal {
        width: 48rem;
        max-width: calc(100vw - 2rem);
        max-height: calc(100vh - 2rem);
        overflow-y: scroll;

        @include media(xs) {
        }        

        .close-button {
            position: absolute;
            top: 1rem;
            right: 1rem;
      
            .icon {
                width: 2rem;
                height: 2rem;
                color: map-get($colors, red);
                transition: 0.3s;
            }

            &:hover {

                .icon {
                    transform: rotate(90deg);
                }
            }
      
        } // close-button      

    }

}

#portal-auth-modal {

    .modal {
        width: 30rem;
        border-radius: 0.5rem;

        .modal-header {
            background: map-get($colors, purple);
            padding: 1.5rem;
            text-align: center;
            color: #fff;

            .close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                
                .icon {
                    width: 2.5rem;
                    height: 2.5rem;
                }

            }

            .image {

                .icon {
                    width: 6rem;
                    height: 6rem;
                    margin: 1rem auto;

                    @include media(xs) {
                        width: 4rem;
                        height: 4rem;
                    }                    
                }

            }

            .title {
                font-size: 1.5rem;
                margin: 0.5em auto 0.25em;

                @include media(xs) {
                    font-size: 1.25rem;
                    margin: 0.25em 0;
                }
            }

        } // header

        .modal-body {
            padding: 2.5rem 10%;
            text-align: center;
            color: map-get($colors, gray);
        } // body

    } // modal

} // #portal-auth-modal