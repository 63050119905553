.site-header {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    padding: 25px;
    z-index: 999;
    pointer-events: none;
    transition: 0.25s;

    .modal--open & {
        opacity: 0;

        .header__branding,
        .header__nav,
        .hamburger {
            pointer-events: none;
        }

    }

    .header__branding {
        display: flex;
        align-items: center;
        max-width: 240px;
        transition: 0.3s;
        pointer-events: auto;

        .drawer--open & {
            @include media(md) {
                opacity: 0;
                pointer-events: none;
            }
        }

        .title {
            flex: 1;
            margin: 0;

            &.breadcrumb {
                flex-basis: 25%;
            }
        }

        .breadcrumb-link {
            padding-left: 10%;
            margin-left: 10%;
            flex-basis: 70%;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 80%;
                width: 0;
                top: 20%;
                left: 0;
                border-left: 1px solid #94928f;
            }
        }
    }

    .header__nav {
        display: block;
        pointer-events: auto;

        @include media(md) {
            display: none;
        }
    }

    .hamburger {
        display: none;
        pointer-events: auto;

        @include media(md) {
            display: flex;
        }
    }

}