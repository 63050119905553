.accordion {

  .accordion--title {
    @include font-size(1.4rem);
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .icon {
      flex: 0 0 1.5em;
      width: 1.5em;
      height: 1.5em;
      margin-left: 0.5em;
      color: map-get($colors, red);
    }

  }

  .accordion--content {
    display: none;
    padding: 1rem 0;
  }

  &.accordion--open {

    .accordion--content {
      display: initial;
      @include font-size(1.15rem);
    }

    .accordion--title {
      .icon {
        transform: rotate(180deg);
      }
    }

  } // open

}