.section--submenu {
    padding: 1rem 0;

    nav {

        a {
            @include link(map-get($colors, gray-dark));
            @include link-underline();
            @include font-size(1.35rem);
            font-weight: $semibold;
            margin: 1rem 1.5rem;
            display: inline-block;
        }

    }

    @include media(xs) {
        display: none;
    }

}
