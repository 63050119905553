// A map of breakpoints (matches flexboxgrid.css)
// -------------------------

$breakpoints: (
  xs: 30em,
  sm: 48em,
  md: 64em,
  lg: 75em,
  xl: 90em,
);

@mixin media($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// Hidden/visible classes
// -------------------------

@each $key, $val in $breakpoints {
  .hidden-#{$key} {
    display: initial;
    @media (max-width: map-get($breakpoints, $key)) {
      display: none !important;
    }
  }
  .visible-#{$key} {
    display: none;
    @media (max-width: map-get($breakpoints, $key)) {
      display: initial !important;
    }
  }  
}
