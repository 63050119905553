@mixin form {

    label {
        font-size: 0.9rem;
        font-weight: $semibold;
        color: map-get($colors, gray);
    }
    
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    select,
    textarea {
        @include input;
        margin-top: 0.35em;
    }

    textarea {
        height: 5em;
    }

    [type="submit"] {
        @extend .button;
        @extend .button--purple;
    }

    .wpcf7 & {

        .wpcf7-not-valid-tip {
            margin-top: 0.25em;
    
            &:before {
                display: inline-block;
                width: 0.5em;
                height: 0.5em;
                transform: rotate(-45deg);
                border: solid 2px currentColor;
                border-bottom: none;
                border-left: none;
                content: '';
                margin: 0 0.5em;
            }
        }

        .wpcf7-response-output {
            padding: 0.7em 1em;
            margin: 1em 0;
            line-height: 1.15;
            font-size: 90%;
            border-radius: $border-radius;
        }

        &.invalid .wpcf7-response-output,
        &.unaccepted .wpcf7-response-output {
            border: none;
            background: #ffb900;
            color: #000;
        }

        &.failed .wpcf7-response-output,
        &.aborted .wpcf7-response-output {
            border: none;
            background: map-get($colors, red);
            color: #fff;
        }  
        
        &.sent .wpcf7-response-output {
            border: none;
            background: #46b450;
            color: #fff;
        }
    

    }

}

.wpcf7 form {
    @include form;
}