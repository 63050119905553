@mixin link($colour, $thickness: 0.2rem) {
    color: $colour;
    font-weight: $bold;
    text-decoration-thickness: $thickness;

    &:hover {
        @if $colour == white {
            color: darken($colour, 25%);
        } @else {
            color: lighten($colour, 25%);
        }
        transition: all 0.3s ease;
    }

    &:active {
        @if $colour == white {
            color: darken($colour, 25%);
        } @else {
            color: lighten($colour, 25%);
        }
    }
    
}

.link-back {
    display: inline-flex;
    align-items: center;

    .icon {
        width: 1.5em;
        height: 1.5em;
        display: inline-block;
        margin-left: -0.35em;
        margin-right: 0.35em;
    }
}
