@mixin spinner {
    position: relative;

    &::after {
        // placeholder loading spinner
        visibility: visible;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        border: 4px solid transparent;
        border-left-color: map-get($colors, red);
        border-right-color: map-get($colors, purple);
        animation: loading-spinner 0.65s linear infinite;

        @keyframes loading-spinner {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        } 
    }    
}

[data-loading="true"] {
    visibility: hidden;
    @include spinner;  
}

.loading-overlay {
    @include spinner;  

    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(map-get($colors, purple), 0.5); 
    transition: 0.3s;   

    &.hidden {
        opacity: 0;
        pointer-events: none;

        &:after {
            display: none;
        }
    }
}

