// blocks/news.twig
// pages/workshops.twig

.section--news {
    background: map-get($colors, gray-lighter);
}

.section--news, 
.section--workshops {

    .news-container {
        height: calc(100% - 1rem);
        text-align: left;
    }

    .news-article {
        @include font-size(1rem);
        background: white;
        color: map-get($colors, gray);
        height: 100%;
        transition: 1s $easeInOutCirc;

        &:hover {
            box-shadow: 0 40px 40px rgba(#000, 0.1);
        }

        .news-image {
            padding-top: 45%;
            background-color: map-get($colors, purple);
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            transition: 1s $easeInOutCirc;
    
            img {
                position: absolute;
                left: -9999px;
            }
    
            &:hover {
                background-size: 104%;
            }
        }
    
        .news-meta {
            padding: 2rem;
        }

        .news-title {
            @extend .highlight;
            @include font-size(1.2rem);
            color: map-get($colors, gray-dark);
            font-weight: 700;
            margin-bottom: 1em;

            a {
                @include link(map-get($colors, gray-dark));
                text-decoration: none;
            }

        }        
    }


}
