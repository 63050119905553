.section--search {

  .search-term {
    background: rgba(#ff0, 0.65);
    display: inline-block;
    padding-left: 0.25em;
    padding-right: 0.25em;
    margin-left: -0.25em;
    margin-right: -0.25em;
    z-index: -1;
    position: relative;
  }

  .search-results {
    margin-top: 3rem;

    .search-result {
      margin-bottom: 2rem;

      .title {
        font-size: 1.25rem;

        a {
          color: inherit;
        }

      }

      p {
        font-size: 0.9rem;
      }

    }

  }
      
}