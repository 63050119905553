// Global menu styles
// -------------------------

$menu-vertical-breakpoint: xs; // when menus go vertical

.menu {
  display: flex;
  flex-direction: row;

  @include media($menu-vertical-breakpoint) {
    flex-direction: column;
    align-items: stretch;
  }

  .menu-item {
    font-weight: $semibold;

    .menu-drop {
      display: none;
    }

    a {
      text-decoration: none;
    }

    > .menu-link,
    > .children .menu-link {
      position: relative;

      @include media($menu-vertical-breakpoint) {
        text-align: center;
        padding: 2rem;
      }

      [data-toggle] .icon {
        width: 3rem;
        height: 3rem;
        position: absolute;
        padding: 0.8rem;
        right: 1rem;
        top: 50%;
        transform: translate(0, -50%) rotate(-90deg);
        display: none;
        transition: 0.3s;
        
        @include media($menu-vertical-breakpoint) {
          display: inline-block;
        }
  
      }   

    } // > .menu-link    

    &.open {

      [data-toggle] .icon {
        transform: translate(0, -50%) rotate(0deg);
      }

      .menu-drop {
        display: block !important;
      }

    } // open   

  } // menu-item

} // menu


// Header menus
// -------------------------

.header__nav {

  .menu {
  
    .menu-item {
  
      a {
        @include link(map-get($colors, white));
      }
    
      .wpml-ls {
        padding: 0;
        border: none;

        .wpml-ls-item {
          display: inherit;
        }

        a {
          padding: 0;
        }
      }
  
    } // menu-item
  
    &.menu-main {
  
      .menu-item {
        margin-right: 3rem;
  
        &:last-child {
          margin-right: 0;
        }
  
        .menu-link {
          @include link-underline;
          padding: 0.25em 0;
        }
  
        &.active {
  
          .menu-link {
            border-bottom: 4px solid transparent;
            border-bottom-color: map-get($colors, red);
          }
  
        }
  
      }
  
    } // menu-main
  
    &.menu-utility {
      font-size: 90%;
      margin-bottom: 1.25rem;
  
      .menu-item {
        margin-right: 2rem;

        a {
          @include link(map-get($colors, red));
        }

      }
  
    } // menu-utility
  
  } // menu
  
} // header__nav


// Drawer menus
// -------------------------

.drawer__nav {

  .menu-drawer {
    flex-direction: column;
    border-top: solid 1px rgba(map-get($colors, purple-dark), 0.5);
    border-bottom: solid 1px rgba(map-get($colors, purple-dark), 0.5);
    margin: 1.5rem 0;
    padding: 1.5rem 0;

    .menu-item {
      margin: 0;

      .menu-link {
        @include link(map-get($colors, white));
        display: block;
        font-size: 1.25rem;
        font-weight: $semibold;
        padding: 0.65rem 1.5rem;
        text-decoration: none;
        text-align: left;
      }

      &.current-menu-item {

        .menu-link {
          color: map-get($colors, red);
        }

      }

    }

  } // menu-drawer

  .menu-utility {

    .menu-link, 
    .wpml-ls-link {
      @include link(map-get($colors, white));
      display: block;
      font-size: 1rem;
      font-weight: $semibold;
      padding: 0.5rem 1.5rem;
      text-decoration: none;
      text-align: left;
    }   
    
    .wpml-ls-legacy-list-horizontal {
      border: none;
      padding: 0;
    }

  }
  
} // drawer__nav

// Footer menus
// -------------------------

.site-footer {

  .menu-footer {
    text-align: left;
    max-width: $site-max-width;
    flex-wrap: wrap;

    > .menu-item {
      display: flex;
      flex-direction: column;
      padding-right: 2rem;
      flex: auto;
      margin-bottom: 2rem;

      &:last-child {
        padding-right: 0;
      }

      @include media(sm) {
        flex: 0 0 33%;
      }

      @include media($menu-vertical-breakpoint) {
        padding: 0;
        margin-bottom: 0;
        justify-content: center;
        border-bottom: 1px solid rgba(#fff, 0.1);
      }      

      > .menu-link,
      > .children .menu-link {
        font-size: 1.15rem;
        margin-bottom: 1.35em;
        display: block;
        @include link(map-get($colors, white));
        font-weight: $semibold;

        @include media($menu-vertical-breakpoint) {
          margin: 0;
        }

      } // > .menu-link

      > .children .menu-item:not(:last-child) {
        
        @include media($menu-vertical-breakpoint) {
          border-bottom: 1px solid rgba(#fff, 0.1);
        }

      }

      .menu-drop {
        @include font-size(0.9rem);
        margin-top: -0.5rem;
        display: block;

        @include media($menu-vertical-breakpoint) {
          margin-top: 0;
          margin-bottom: 1.5rem;
          display: none;
        }

        .menu-item {
          margin: 0.5em 0;
          
          .menu-link {
            @include link(rgba(#fff, 0.55));
            font-weight: normal;
            
            @include media($menu-vertical-breakpoint) {
              text-align: center;
              padding: 0.5em;
              display: block;
            }

          }

        }
        

      } // menu-drop
  

    } // > .menu-item

  } // menu-footer

  .menu-social {
    display: flex;
    flex-wrap: wrap;
    //margin-top: 0.5rem;

    @include media($menu-vertical-breakpoint) {
      margin: 1.5rem 0;
      justify-content: center;
    }

    li {
      font-size: 1.6rem;
      line-height: 1;
      margin: 0 0.35em;

      @include media(sm) {
        margin: 0.15em;
      }

      @include media($menu-vertical-breakpoint) {
        font-size: 2rem;
      }      

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      a {
        width: 1em;
        height: 1em;
        display: inline-block;
      }

      svg {
        color: map-get($colors, gray-lighter);
        fill: map-get($colors, gray-lighter);
        width: 1em;
        height: 1em;

        &:hover{
            opacity:.9;
        }

      }
    
    }

  }

  .menu-policy {
    font-size: 0.9rem;

    @include media($menu-vertical-breakpoint) {
      margin-bottom: 1rem;
    }

    .menu-item {
      margin: 0.5em 1.25em;
    }

    .menu-link {
      @include link(rgba(#fff, 0.55));
      font-weight: normal;
    }
    
  }

} // site-footer
