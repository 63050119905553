@mixin input {
    font-size: 1.1rem;
    padding: 0.5em;
    border-radius: $border-radius;
    border: solid 2px map-get($colors, purple);
    background: map-get($colors, white);
    transition: border 0.3s;
    width: 100%;
    max-width: 100%;

    &:focus {
        background: map-get($colors, white);
        border-color: map-get($colors, purple-light);
        outline-offset: 4px;
    }
}