#footer {
    background: map-get($colors, blue);
}

.site-footer {
    color: map-get($colors, gray-lighter);
    padding: #{$section--padding} 0;

    @include media(xs) {
        padding: 0;

        .menu-policy {
            margin-top: 2rem;
        }        
    }

}
