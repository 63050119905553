@import "../../parent/components/button";

// youth customizations

.button {
    font-size: 1.1875rem;
    border-radius: 9999px;
    font-family: $heading-font-family;
    font-weight: normal;
    padding: 0.85em 1.5em;
    box-shadow: $shadow;

    @include media(sm) {
        font-size: 1rem;
    }

    &:hover {
        box-shadow: $shadow-hover;
    }

}
