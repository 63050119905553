@import "../../parent/components/menu";

// youth overrides  

.site-footer {

    .menu-footer > .menu-item {

        > .menu-link, 
        > .children .menu-link {
            font-size: 1rem;
        }
    
        .menu-drop {
            font-size: 0.8rem;
        
            .menu-item {
                margin: 1em 0; 
            }        
        }
    }

} // site-footer