// Fonts
// -------------------------

$base-font-family: montserrat, sans-serif;
$heading-font-family: zeitung-mono, monospace;
$heading-color: map-get($colors, blue);
$bold: 700;
$semibold: 600;

// Styles
// -------------------------

$border-radius: 8px;
$shadow: 0px 4px 0 rgba(0, 0, 0, 0.15);
$shadow-hover: 0px 12px 0 5px rgba(0, 0, 0, 0.05);
$icon-default: 6rem;
$icon-small: 3rem;

// Layout
// -------------------------

$site-max-width: 100rem;
$site-header-height: 10rem;
$site-header-height-mobile: 6.5rem;
$section--content-padding: 5rem;
$section--padding: 5%;

