.section--sponsors {
    background: map-get($colors, white);

    .sponsor-images {

        .sponsor {
            display: flex;
            align-items: center;
            max-width: 225px;
            margin: 1.5em 3em;

            @include media(sm) {
                max-width: 125px;
                margin: 1em 1em;
            }

        }

        .label {
            padding: 5px 15px;
            background-color: map-get($colors, gray-lighter);
        }
                
    }
}
