@mixin callouts {

    .callouts {
        padding-bottom: 10%;

        @include media(md) {
            padding-bottom: 0;
        }

        .callout-wrapper {
            height: 100%;
            position: relative;
            padding-bottom: 1rem;
        }
    
        .callout {
            position: relative;
            padding: 3rem 2rem;
            transition: 1s $easeInOutCirc;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: stretch;
            box-shadow: $shadow;
            border-radius: $border-radius;
            background-repeat: no-repeat, no-repeat;
            background-position: center, top center;
            background-size: cover, cover;
            //background-blend-mode: luminosity;
    
            @include media(xs) {
                padding: 2rem 1rem;
            }
    
            &:hover {
                box-shadow: $shadow-hover;
            }
    
            .title {
                font-size: 1.6rem;
                margin: 1em 0;

                @include media(xl) {
                    font-size: 1.3rem;
                }    
                
                @include media(md) {
                    font-size: 2rem;
                }                  
    
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
    
            p {
                font-size: 1.15rem;
                line-height: 1.4;

                @include media(xl) {
                    font-size: 1rem;
                }    
                
                @include media(md) {
                    font-size: 1.25rem;
                }                 
            }
    
            .links {
                padding-top: 2rem;
                margin-top: auto;
    
                .link {
                    color: currentColor;
                }
    
            }
    
            .arrow {
                position: absolute;
                width: 50%;
                padding-top: 27.5%;
               
                &.arrow-below {
                    top: 100%;
                    right: 0;
                }
        
                &.arrow-above {
                    bottom: 100%;
                    right: 0;
                    transform: scaleY(-1);
                }
        
                .icon {
                    position: absolute;
                    top: 0; left: 0; 
                    width: 100%; height: 100%;
                }
        
            }            
    
        } // callout
    
    
        [class*="col"] {
            &:nth-child(even) .callout {
                top: 20%;
            }
        }
    
        @include media(md) {
            [class*="col"] {
                &:nth-child(even) .callout {
                    top: 0;
                }
            }

        }

    } // callouts


} 