.section--callouts {
    background: map-get($colors, gray-lighter);

    @include callouts;

    .callouts {
        margin-top: 2rem;
        color: #fff;

        .callout {

            .image {
                width: 100%;
                height: $icon-default;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin: 25% auto;
            }

            .no-image {
                padding-top: 50%;
            }

            .title {
                margin-top: auto;
                hyphens: auto;
            }

            .links {
                margin-top: 0;
            }

        }

    } // callouts
    
    .section-footer {
        margin-top: 0;
    }    

}