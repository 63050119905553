.section--404 {
    padding: 10vh 2rem;
    min-height: 100vh;
    background: map-get($colors, purple);
    align-items: center;
    justify-content: center;
    display: flex;

    .title {
        font-size: 8rem;
        color: map-get($colors, red);
        margin: 0 auto;
    }
}