@import "../parent/layout";

.section {
    position: relative;
    z-index: 0;

    > .background {
      position: absolute !important;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-attachment: fixed;
      z-index: 0;
      mix-blend-mode: luminosity;
      opacity: 0.25;

      & + [class*="container"] {
        position: relative;
        z-index: 1;
      }

    } 

    &.has-shapes > :not(.floating-shape) {
        position: relative;
        z-index: 1;
    }

    .section-header {

        @include media(xs) {
            margin-top: 1rem;
        }

        h1, 
        h2, 
        h3, 
        h4, 
        h5, 
        h6 {
          font-family: $heading-font-family;
          color: map-get($colors, red);
        }
        
        h1 { @include font-size(2.4rem); }
        h2 { @include font-size(2rem); }
        h3 { @include font-size(1.75rem); }
        h4 { @include font-size(1.5rem); }
        h5 { @include font-size(1.25rem); }
        h6 { @include font-size(1rem); }        

        .icon {
            width: 140px;
            max-width: 80%;

            @include media(sm) {
                margin-bottom: 2rem;
            }
        }

        .title {
            margin-bottom: 0.5em;
        }
    
        p {
            font-size: 1.35rem;

            @include media(xs) {
                font-size: 1.15rem;
            }            
        }
    
    }

}

.floating-shape {
    position: absolute;
    z-index: 0;
    pointer-events: none;

    svg {
        transform: scale(0.5);        
    }

    &.top { 
        top: 0;
    }

    &.bottom { 
        bottom: 0;
    }

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    @include media(sm) {

        svg {
            transform: scale(0.25);
        }

        &.top { 
            top: -15%;
        }
    
        &.bottom { 
            bottom: -15%;
        }
    
        &.left {
            left: -15%;
        }
    
        &.right {
            right: -15%;
        }

    }

}