@mixin project-directory {

    .title-project-directory {
        text-transform: uppercase;
        opacity: 0.5;
        margin-bottom: 1.5rem;
    }

    .challenges {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 3rem;
    }

    .challenge {
        flex: 0 0 20%;
        cursor: pointer;
        padding: 1rem;

        @include media(md) {
            flex-basis: 25%;
        }        

        @include media(sm) {
            flex-basis: 33.3%;
        }

        @include media(xs) {
            flex-basis: 50%;
        }

        .challenge-icon {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            border-radius: 999px;
            box-shadow: inset 0 0 0 6px map-get($colors, purple-lighter);
            transition: 0.5s $easeInOutCirc;
            backface-visibility: hidden;

            svg {
                position: absolute;
                width: 60%;
                height: 60%;
                top: 50%;
                left: 50%;
                fill: map-get($colors, purple);
                transform: scale(0.9909) translate(-50%, -50%);
                transform-origin: top left;
                transition: 0.5s $easeInOutCirc;
                backface-visibility: hidden;
            }

        } // challenge-icon

        .challenge-content {
            @include font-size(1.25rem);
            line-height: 1.1;
            min-height: 2.2em;
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .challenge-label {
            display: block;
            text-align: center;
            transition: 0.5s $easeInOutCirc;
        }

        &.active, 
        &:focus-within,
        &:hover {

            .challenge-icon {
                box-shadow: inset 0 0 0 10px map-get($colors, red);

                svg {
                    transform: scale(0.95) translate(-50%, -50%);
                }
            }

            .challenge-label {
                color: map-get($colors, red);
            }

        } // active, hover

        &.active {

            .challenge-icon {
                svg {
                    fill: map-get($colors, red);
                }
            }

        } // active, hover

    } // challenge

    .filters form {
        @include form;
        display: flex;
        width: auto;
        max-width: none;
        margin-bottom: 2rem;

        input[type="search"],
        select {
            flex: 1;
            margin: 0.5rem;
            width: auto;
            max-width: none;
        }

        @include media(xs) {
            flex-direction: column;
        }

    }

    .results {
        background: map-get($colors, gray-lighter);
        padding: 3rem 0;

        .results-header {
            margin-top: 2rem;
            margin-bottom: 2rem;

            .found-label {
                @include font-size(1.5rem);
            }
    
            .clear-filters-label {

            }  

        }

        [data-load-more] {
            min-height: 100px;
        }

        .project {
            background: map-get($colors, white);
            border-radius: $border-radius;
            position: relative;
            box-shadow: $shadow;
            height: calc(100% - 1rem);
            text-align: left;
            padding: 3rem 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 1;
            cursor: pointer;
            text-decoration: none;
            transition: 0.5s $easeInOutCirc;
            overflow: hidden;

            &:before {
                position: absolute;
                top: 0; left: 0; right: 0;
                height: 1rem;
                background: map-get($colors, purple-dark);
                display: block;
                content: '';
                transition: 0.5s $easeInOutCirc;
            }

            .project-year {
                color: white;
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5em 1em;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                transition: 0.5s $easeInOutCirc;

                &:before {
                    content: '';
                    transform: skew(-16.5deg);
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: -2rem;
                    background: map-get($colors, red);
                    z-index: -1;
                }

            }

            .project-name {
                @include font-size(1.15rem);
                margin-bottom: 1em;
            }

            .project-members {
                @include font-size(1rem);
                .project-member {
                }
    
            }

            &:hover, 
            &:focus-within {
                z-index: 99;
                box-shadow: $shadow-hover;
                transform: translate(0, -0.5rem);

                &:before {
                    transform: translate(0, -0.5rem);
                }

                .project-year {
                    transform: translate(-0.5rem, 0);
                }

            } // hover

        } // project

    } // results

    .modal {
        padding: 4rem;

        @include media(xs) {
            padding: 1rem;
        }        

    } // modal

}

// partials/project-detail.twig

.project-detail {
    @include font-size(1.15rem);

    .event-name {
        margin-bottom: -1rem;
        padding-right: 4rem;
    }

    .section-title {
        margin-bottom: 1em;
    }    

    .project-header {

        .project-image {
            display: block;
            width: 100%;
            height: auto;
            background: #eee;
            border-radius: 4px;

            @include media(xs) {
                margin-bottom: 1rem;
            }
        }
    
        .project-name {
            @include font-size(2rem);
            line-height: 1.1;
            margin-bottom: 0.5em;
        }
    
        .project-members {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
    
            .project-member {
                @include font-size(1.25rem);
                line-height: 1;
    
                &:after {
                    content: '|';
                    font-weight: bold;
                    display: inline-block;
                    color: map-get($colors, red);
                    margin: 0 0.5em;
                    font-size: 150%;
                    vertical-align: middle;
                }

                &:last-child:after {
                    visibility: hidden;
                }
            }
        }

        .project-link {
            display: inline-flex;
            align-items: center;
            @include font-size(1rem); 
    
            .icon {
                height: 1.25em;
                width: 1.25em;
                margin: -0.1em 0.5em 0 0;
                color: map-get($colors, red);
            }
    
        }

    } // project-header

    .project-meta {
        
        .meta {
            display: flex; 
            margin: 0.35em 0;          

            .meta-key {
                flex: 0 0 5.5em;
                margin-right: 1em;
            }
    
            .meta-value {
                padding-right: 1em;
                flex: auto;
            }    

        }

    } // project-meta

    .project-abstract {

        .read-more {
            font-size: 1rem;
            display: inline-flex;
            align-items: center;

            .icon {
                height: 1.25em;
                width: 1.25em;
                margin: -0.2em 0 0 0.5em;
                color: map-get($colors, red);
            }

        }

    } // project-abstract

    .project-awards {

        .award {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            .award-name,
            .award-value {
                @include font-size(1.35rem);
            }

            .award-medal,
            .award-sponsored-by {
                margin-top: 0.35em;
                display: block;
            }
            .award-sponsored-by {
                @include font-size(0.9rem);
            }

        }

    } // project-awards

    .project-biographies {

        .biography {

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            p {
                @include font-size(1rem);
            }

        }

    }

    /*


    .award {
        margin-bottom: 1em;
    }

    .award-name {
        @include font-size(32rem / 24);
    }

    .award-medal {
        @include font-size(32rem / 24);
    }

    .award-value {
        @include font-size(32rem / 24);
    }

    .award-sponsored-by {
        @include font-size(28rem / 24);
    }

    .biography-name {
        @include font-size(32rem / 24);
        border-left: 4px solid map-get($colors, red);
        padding-left: 0.5em;
    }

    .biography {
        @include font-size(28rem / 24);
    }
    */
}