@import '../../parent/blocks/news';

.section--news, 
.section--workshops {
    .news-article {
        box-shadow: $shadow;
        &:hover {
            box-shadow: $shadow;
        }
        .news-title {
            font-weight: 600;
            font-family: $heading-font-family;
            letter-spacing: -0.030em;
        }
    }
}
