body.single-featured-student {

    .article {
        @include author;
        @include content();

        .author {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: dashed 2px map-get($colors, gray-light);
        }
    }

}