@mixin content() {
    @include font-size(1.2rem);

    .title {
        @include font-size(2.5rem);
    }

    h1 {
        @extend .title;
        color: map-get($colors, red);
    }

    h2 {
        @include font-size(2rem);
        color: map-get($colors, purple);
    }

    h3 {
        @include font-size(1.6rem);
        color: map-get($colors, blue);
    }    

    p {
        margin: 1.25em 0;
    }

    a {
        @include link(map-get($colors, purple));
    }

    ul,
    ol {
        margin: 1.25rem 0;
        padding-left: 2.5rem;
        
        li {
            margin: 0.5em 0;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    .inline-image {
        padding: 3em;
        max-width: 50%;
        display: block;

        @include media(md) {
            max-width: 65%;
        }

        @include media(sm) {
            max-width: 60%;
            padding: 1.5em;
        }
        
        &.image--alignleft {
            float: left;
            margin-left: -6em;
            padding-left: 0;

            @include media(sm) {
                margin-left: -3em;
            }            
        }

        &.image--alignright {
            float: right;
            margin-right: -6em;
            padding-right: 0;

            @include media(sm) {
                margin-right: -3em;
            }            

        }        

        img {
            box-shadow: $shadow;
            position: relative;
            height: auto;
            max-width: 100%; // 100% of the wrapper
            width: 100% !important;
        }

        @include media(xs) {
            max-width: none;
            padding: 0;

            &.image--alignleft,
            &.image--alignright {
                float: none;
                margin: 2rem -1rem;
            }

        }

    }    
}