@import '../../parent/blocks/testimonials';

.section--testimonials {
    background: map-get($colors, blue);

    .testimonial-block {
        .author {
            font-family: $heading-font-family;
        }
    }

    .tns-nav {
        button {
            background-color: rgba(#000, 0.5);
        }
        &.tns-nav-active {
            box-shadow: 0px 3.5px 1.5px rgba(#000, 0.2);
        }
    }

}
