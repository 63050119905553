.section--gallery {

    .gallery-item {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        .item-link {
            display: block;
            padding-top: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.5s $easeInOutCirc;

            .item-image {
                position: absolute;
                left: -9999px;
            }

            &:hover {
                box-shadow: inset 0 0 0 1rem map-get($colors, red);
            }
        }


    }

}