$arrow-size: 3rem;

.hero-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-self: flex-end;
  margin-top: auto;
  padding: 1rem;
  position: relative;
  z-index: 1;

  @include media(xs) {
    display: none;
  }

  .nav-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;
    
    &:hover {
      opacity: 0.75;
    }

    &.nav-item--prev {

      .icon {
        transform: rotate(180deg);
        margin-right: 0.5rem;
      }

    }

    &.nav-item--next {
      flex-direction: row-reverse;

      .icon {
        margin-left: 0.5rem;
      }

    }

    .icon {
      color: white;
      width: $arrow-size;
      height: $arrow-size;
    } 
    
    .item-meta {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .title {
        font-size: 2rem;
        margin: 0;
      }
  
    }

  }

}
