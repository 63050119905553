.section--youth-news-slider {
    background-color: map-get($colors, gray-lighter);
    padding-top: 0;
    padding-bottom: 0;

    .news-container {
        display: flex;
        align-items: stretch;
        position: relative;
        z-index: 1;
        height: 100%;

        @include media(md) {
            flex-direction: column-reverse;
        }

        &:after {
            content: '';
            transform: skew(-13.1deg);
            position: absolute;
            top: 0;
            left: 30%;
            bottom: 0;
            right: 50%;
            background: map-get($colors, gray-lighter);
            z-index: -1;
            transform-origin: bottom right;
        }

        @include media(md) {
            height: auto;

            &:after {
                display: none;
            }
        }
    }

    .article {
        flex: 1;
        min-height: 70vh;
        display: flex;
        align-items: center;

        @include media(md) {
            min-height: 0;
            padding: 5rem 10% 6rem;
        }

        @include media(xs) {
            padding-top: 2rem;
        }

        .article-details {
            margin: 15% 0;

            @include media(md) {
                margin: 0;
            }

            .title {
                @include font-size(2.4rem);
                line-height: 1.45;

                a {
                    font-weight: inherit;
                    color: inherit;
                    text-decoration: none;
                }
            }

            @include author;

            .excerpt {
                font-size: 1.15rem;
                line-height: 1.35;
                margin: 1.5rem 0;
                max-width: 90%;
            }
        }
    }

    .image {
        flex: 1;
        position: relative;
        z-index: -2;

        img {
            object-fit: cover;
            object-position: left;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .tns-slider {
        display: flex;
        transition-timing-function: $easeInOutExpo;

        .slide {
            opacity: 0;
            transition: opacity 0.35s;
        }

        .slide.tns-slide-active {
            opacity: 1;
        }
    }

    .tiny-slider-wrapper {
        position: relative;
    }

    .tns-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: $section--padding;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(map-get($colors, blue), 0.75);
        border-radius: 999px;

        button {
            margin: 10px;
            cursor: pointer;
            width: 12px;
            height: 12px;
            background-color: rgba(#000, 0.75);
            border: 0;
            padding: 0;
            border-radius: 10px;
            transition: 0.25s;

            &.tns-nav-active {
                background-color: white;
                transform: scale(1.25);
            }
        }
    }
}
