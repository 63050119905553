.searchandfilter {

  > ul {
    display: flex;

    > li {
      flex: 1;
      margin: 0.5rem;

      label {
        width: 100%;
      }

      select, input {
        width: 100%;
        @include input;
      }

      .chosen-container-multi .chosen-choices {
        @include input;
      }

      .chosen-container-multi .chosen-choices li.search-field input[type=text] {
        @include input;
        padding: 0;
      }
      
      .chosen-container-multi .chosen-choices li.search-choice {
        font-size: 1rem;
        margin: 0 0.25em 0 0;
        background: map-get($colors, blue);
        color: white;
        padding: 0.25em 1.5em 0.25em 0.75em;
        border: none;
        border-radius: 99px;
        box-shadow: none;
        line-height: 1;

        .search-choice-close {
          background: none;
          background-image: none !important;
          top: 50%;
          right: 0.35em;
          font-size: 1.1rem;
          transform: translate(0, -50%);

          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            content: "\00d7"; 
            opacity: 0.65;
          }

        }

      }

    }

  }

  /*
  select {
    padding: .6em 1.4em .5em .8em;
    border: 1px solid map-get($colors, red);
    border-radius: $border-radius;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
    option {
      font-weight: normal;
    }
  }

  input{
    padding: .6em 1.4em .5em .8em;
    border: 1px solid map-get($colors, red);
    border-radius: $border-radius;
  }
  */

}