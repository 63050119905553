@import "../../parent/components/link";

// link colors

@each $key, $val in $colors {
    .link--#{$key} {
        @include link($val, 0.1rem);
    }
}

.link-arrow {
    display: inline-flex;
    align-items: center;

    .icon {
        width: 2em;
        height: 2em;
        margin-left: 0.5em;
        transition: 0.3s;
        transform: translate(0, 0);
    }

    &:hover {
        
        .icon {
            transform: translate(5px, 0);
        }

    }

}