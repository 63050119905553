.text--bold {
  font-weight: $semibold;
}

.title {
  font-family: $heading-font-family;
  font-weight: normal;
  margin-bottom: 1em;
}

.text-align--right {
  text-align: right;
}

.text-align--left {
  text-align: left;
}

.text-align--center {
  text-align: center;
}

// Selectors that might contain wordpress WYSIWYG output as direct children
#page-content, // our template but not inside a block
.content--block, // used inside our blocks
.section-content, // TODO to be moved into .section rules
//.section-header,
.wp-block-column, // built-in block 'core/columns'
.wp-block-media-text__content // built-in block 'core/media-text'
{
  color: map-get($colors, gray-dark);
  @include font-size(1.15rem);

  // built-in block 'core/paragraph', and any paragraphs in WYSIWYGs

  p {
    @include font-size(1.15rem);
  }
  
  // built-in block 'core/list', and any lists in a WYSIWYG

  ul:not([class^='wp-block-']),
  ol:not([class^='wp-block-']) {
      @include font-size(1.15rem);
      list-style: disc;
      padding: 0 2rem;    
      margin: 1.5rem 0;
      
      li {
        margin: 0.5em 0;
      }

  }

  // built in block 'core/heading', and any headings in a WYSIWYG

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    font-family: $heading-font-family;
    color: $heading-color;
  }
  
  h1 { @include font-size(64rem / 24); }
  h2 { @include font-size(56rem / 24); }
  h3 { @include font-size(48rem / 24); }
  h4 { @include font-size(36rem / 24); }
  h5 { @include font-size(28rem / 24); }
  h6 { @include font-size(24rem / 24); }
}


  