@mixin line-bottom($spacing, $color: red) {
    position: relative;
    margin-bottom: $spacing;
    padding-bottom: $spacing;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-bottom: 4px solid map-get($colors, $color);
        width: 25%;
    }
}

// TODO: use line-bottom mixin
.line-bottom {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 50px;

    // TODO: use pseudo mixin
    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-bottom: 4px solid map-get($colors, red);
        width: 25%;
    }
}
