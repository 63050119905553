.section--project-database {
    padding-bottom: 0;

    @include project-directory;

    .title-project-directory {
        text-transform: none;
        opacity: 1;
        color: map-get($colors, blue) !important;
    }

    .challenge {

        .challenge-icon {
            box-shadow: none;

            svg {
                fill: #fff;
            }

        }

        &:not(.active):hover {

            .challenge-icon {
                box-shadow: none;
            }

        }

        &[data-challenge=""] .challenge-icon {
            background: map-get($colors, purple-dark);
        }

        &.active .challenge-icon {
            background-color: transparent !important;
        }

    }
    

} // project-database