// site palette

$colors: (

  // grays
  white: #ffffff,
  gray-lighter: #eceff0,
  gray-light: #ddddde,
  gray:#82838f,
  gray-dark: #3d3d44,
  gray-darker: #121212,
  
  // unified palette
  red: #ff364d,
  orange: #ff731f,
  yellow: #ffc425,
  green: #8dc73e,
  teal: #1a9dc3,
  blue-light: #2bc5ec,
  blue: #3339ae,
  purple: #914ae1,
  pink: #ff4cd7,

  // custom colours
  purple-dark: #31136d,  

);

/* Helper classes */

// background colors

@each $key, $val in $colors {
  .bg--#{$key} {
    background-color: #{$val};
  }
}

// text colors

@each $key, $val in $colors {
  .text--#{$key} {
    color: #{$val};
  }
  .title--#{$key} {
    color: #{$val};
  }
}

// stroke colors

@each $key, $val in $colors {
  .stroke--#{$key} {
    stroke: #{$val};
  }
}

// fill colors

@each $key, $val in $colors {
  .fill--#{$key} {
    fill: #{$val};
  }
}

// border colors

@each $key, $val in $colors {
  .border--#{$key} {
    border-color: #{$val};
  }
  .border-top--#{$key} {
    border-top-color: #{$val};
  }
  .border-bottom--#{$key} {
    border-bottom-color: #{$val};
  }
  .border-right--#{$key} {
    border-right-color: #{$val};
  }
  .border-left--#{$key} {
    border-left-color: #{$val};
  }
}
