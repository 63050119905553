// Imports
// -------------------------
@import url("https://use.typekit.net/ufb4hbg.css");
@import '~awesome-sass-easing/sass-easing';
@import 'flexboxgrid';
@import 'pickles/scss/ui/lightbox';
@import 'pickles/scss/ui/modal';
@import '~tiny-slider/dist/tiny-slider.css';

// Styles
// -------------------------

@import "../parent/base";

.shape {
  position: absolute;
  z-index: 1;

  * {
    stroke-linejoin: miter;
  }
  
}
