@mixin link-underline($color: red) {
  position: relative;
  text-decoration: none;

  &:after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 4px;
      background: map-get($colors, $color);
      width: 100%;
      display: block;
      content: '';
      transform-origin: right;
      transition: transform 0.25s;
      transform: scaleX(0);
  }

  &:hover:after {
      transform-origin: left;
      transform: scaleX(1);
  }
}

.link-underline {
  @include link-underline;
}