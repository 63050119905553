// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//				Seach form
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.header-search-form,
.drawer-search-form {

  input {
    border: none;
    font: normal 0.95rem $base-font-family;
    appearance: none;
    transition: all 0.6s ease;
    cursor: pointer;
  }

  .icon-wrapper .icon {
  }

  button {
    border: none;
    appearance: none;
    background: none;
    padding: 0;
    display: block;
  }

}

.header-search-form {
  display: table;
  overflow: hidden;

  input {
    background: none;
    outline: none;
    width: 28px;
    min-width: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    line-height: 1;
    margin: 5px 0;
    color: map-get($colors, red);

    & + .icon-wrapper {
      position: relative;
      height: 28px;
      width: 95%;
      margin: -28px 0 0 0;

      .icon {
        display: block;
        position: absolute;
        height: 32px;
        width: 160px;
        right: 0;
        bottom: -5px;
        transition: all 0.6s $easeInOutCirc;
        fill: none;
        stroke: map-get($colors, red);
        stroke-width: 2.5px;
        stroke-dashoffset: 212.908 + 59;
        stroke-dasharray: 59 212.908;    
      }

    }

    &:not(:placeholder-shown),
    &:focus {
      width: 160px;
      padding: 0 4px;
      cursor: text;

      & + .icon-wrapper {

        .icon {
          stroke-dasharray: 150 212.908;
          stroke-dashoffset: 300;
          stroke-width: 4px;
        }

      }

    }

  }

}

.drawer-search-form {
  position: relative;
  padding: 1rem;

  input {
    overflow: visible;
    background-color: map-get($colors, gray-lighter);
    padding: 0.35em;
    width: 100%;
    padding-right: 2rem;
    border-radius: 99px;
  }

  .icon-wrapper {
    position: absolute;
    top: 0; 
    right: 0;
    bottom: 0;
    padding: 1rem;
    color: map-get($colors, red);

    .icon {
      width: 1.8rem;
      height: 1.8rem;
      padding: 0.3rem;
    }
  }
  
}
