.section--youth-hero {

  & > .background:before {
    display: block;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 15rem;
    content: '';
    background: linear-gradient(top, rgba(#000, 0.65) 35%, rgba(#000, 0) 100%);
  }    

  &.light > .background:before{
    background: linear-gradient(top, rgba(#000, 0.9) 35%, rgba(#000, 0) 100%);
  }  

  .box {
    border-style: solid;
    border-width: 10px;
    border-radius: 5px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    
    @include media(sm) {
      border: none;
    }

  }

  color: white;
  min-height: 40vmax;
  padding-top: $site-header-height;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: map-get($colors, blue);

  @include media(xs) {
    padding-top: $site-header-height-mobile;
  }

  @include media(sm) {
      text-align: center;
  }

  .hero-content {
    margin-top: auto;
    margin-bottom: auto;
  }

  @import "../../parent/components/hero-nav";

  .hero-title {
    @include font-size(3rem);
    line-height: 1.25;
    font-weight: $semibold;
    z-index: 1;
    position: relative;
    display: inline-block;
    font-family: $heading-font-family;
    letter-spacing: -0.030em;
    margin-bottom: 0;

    @include media(xs) {
      color:white !important;
    }
  }

  .hero-subtitle {
    @include font-size(1.35rem);
    margin-top: 2em;
    line-height: 1.45;
  }

  .section-footer {
    margin-bottom: -1rem;

    .buttons {
      justify-content: flex-start;
    }

  }

  &.hero--short {
    min-height: 0;

    .box {

      .hero-title {
        @include font-size(2.4rem);
      }
    }
  }
  
}