.section--numbered-leftright-content {
    padding: 0;

    .container-no-gutter {
        position: relative;
        
        &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            right: 100%;
            top: 0;
            display: block;
            content: '';
            background-color: currentColor; // inherit background colour from text colour so it can be set in html
        }

        &.reverse {

            &:before {
                right: auto;
                left: 100%;
            }

            .content--number .title {
                text-align: right;
                left: unset;
                right: 50%;

                @include media(md) {
                    right: 10%;
                }                
            }

            .content--number .number {
                text-align: right;
            }
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
    }

    .section-content {
        text-align: left;
        padding: 15% 10%;

        @include media(xs) {
            //text-align: center;
        }

        a:not(.button) {
            @include link-underline();
        }

        .alert {
            color: white;
            padding: 0.75rem;
            display: inline-flex;
            align-items: center;
            font-family: $base-font-family;
            font-weight: $bold;
            font-size: 1.25rem;
            margin-top: 1.5rem;

            .i {
                font-family: $heading-font-family;
                display: inline-block;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                text-align: center;
                line-height: 2rem;
                background-color: map-get($colors, white);
                margin-right: 0.5rem;
                flex: 0 0 2rem;
            }

            @include media(xs) {
                font-size: 1.1rem;
            }

        }

        .links {
            margin-top: 3rem;
            @include font-size(30rem / 24);
        }

    }

    .content--image {
        display: flex;
        align-items: center;
        justify-content: center;
        color: map-get($colors, white);
        height: 100%;

        @include media(sm) {
            height: auto;
            padding-top: 10%;
            padding-bottom: 10%;
        }

        &.image--icon {
            .svg {
                width: 40%;
                padding-top: 40%;
                position: relative;

                svg {
                    fill: currentColor;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0; left: 0;
                }
            }
        }

        &.image--background {
            height: 100%;
            margin-left: -0.5rem;
            margin-right: -0.5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .content--number {
        display: flex;
        align-items: center;
        height: 100%;
        padding: $section--content-padding;
        position: relative;

        .number {
            color: map-get($colors, white);
            opacity: 0.2;
            font-family: $heading-font-family;
            font-size: 254.6px;
            letter-spacing: -15px;
        }

        .title {
            color: map-get($colors, white);
            font-family: $heading-font-family;
            font-size: 40px;
            position: absolute;
            left: 50%;

            @include media(md) {
                left: 10%;
            }
        }

    }

}
