@import '../../parent/blocks/title';

.section--title {

    .section-header {
        margin-bottom: 0;

        .title {
            color: map-get($colors, blue);
        }

        p {

            img {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

        }

    }

    .section-footer {
        margin-top: 0;
    }

    &.inverse {
        color: #fff;

        .section-header {

            .title {
                color: currentColor;
            }

            p {
                opacity: 0.65;
            }
        }

    }

    .row:not(.text-align--center) {

        .section-footer {
    
            .buttons {
              justify-content: flex-start;
            }
        
        }
    
    }

}