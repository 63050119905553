// blocks/hero.twig

.section--hero {
    color: white;
    min-height: 40vmax;
    padding-top: $site-header-height;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: map-get($colors, purple-dark);
  
    &.hero--short {
      min-height: 25vmax;
  
      @include media(xs) {
        min-height: 35vmax;
      }
  
    }
  
    // top gradient
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $site-header-height;
      background: linear-gradient(top, rgba(map-get($colors, gray-darker), 0.85) 35%, rgba(map-get($colors, gray-darker), 0) 100%);
      z-index: 0;  
    }
  
    @include media(xs) {
      padding-top: $site-header-height-mobile;
  
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        z-index: 0;
        opacity: 0.8;
      }
  
    }
  
    .hero-content {
      margin-top: auto;
      margin-bottom: auto;
    }
  
    @import "../../parent/components/hero-nav";
  
    .hero-title {
      @include font-size(40px);
      line-height: 1.1;
      font-weight: $semibold;
      z-index: 1;
      position: relative;
      text-align: left;
      padding-left: 10%;
      display: inline-block;
  
      &:before {
        content: "";
        position: absolute;
        width: 200%;
        height: 160%;
        padding-left: 100%;
        top: 0;
        bottom: 0;
        right: -10%;
        z-index: -1;
        transform: skew(-16.5deg) translate(0, -20%);
        box-shadow: rgba(#000000, 0.46) 1px 0 5px;
        opacity: 0.8;
        background-color: map-get($colors, red);
      }    
  
      &.border-red-top {
  
        &:after {
          content: "";
          display: none;
          border-top: 4px solid map-get($colors, red);
          top: -50%;
          position: absolute;
          width: 50%;
          margin: auto;
          left: 0;
          right: 0;
  
          @include media(xs) {
            display: block;
          }
        }
  
      }
  
      @include media(xs) {
        color:white !important;
      }
  
      // trapezoid background
  
      @include media(xs) {
        display: block;
        text-align: center;
        padding: 0;
  
        &:before {
          display: none;
        }
    
      }
  
    }
  
    .hero-logo {
      padding-left: 10%;
      max-width: 60%;
      display: inline-block;
      position: relative;
      margin-bottom: 2rem;
      z-index: 0;
  
      &:before {
        content: "";
        position: absolute;
        width: 200%;
        height: 160%;
        padding-left: 100%;
        top: 0;
        bottom: 0;
        right: -10%;
        z-index: -1;
        transform: skew(-16.5deg) translate(0, -20%);
        box-shadow: 0px 2px 5px 0px rgba(#000000, 0.46);
      }    
  
      // trapezoid background
  
      @include media(xs) {
        max-width: 50%;
        padding-left: 0;
      
        &:before {
          display: none;
        }
  
      }
      
    }
  
  }
  