.section--image {
    padding: 0;

    .image {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .parallax-image {
        height: 50vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
    }
}