.section--youth-content {
    padding: 0;

    .container-no-gutter {
        position: relative;
        
        &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            right: 100%;
            top: 0;
            display: block;
            content: '';
        }

        .section-content {
            padding-left: 10%;
        }

        &.reverse {

            &:before {
                right: auto;
                left: 100%;
            }

            .section-content {
                padding-right: 10%;
            }
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
    }

    .content--image,
    .section-content {
        text-align: left;
        padding: $section--content-padding;

        @include media(sm) {
            padding: $section--content-padding / 2;
        }

        @include media(xs) {
            text-align: center;
        }

    }

    .content--image {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            position: relative;
            margin-top: -$section--content-padding * 2;

            @include media(sm) {
                margin-top: 0;
            }
        }
    }

    .section-content {

        .links {
            margin-top: 2rem;
            @include font-size(30rem / 24);
        }

    }

    .wide .section-content {

        padding-top: $section--content-padding * 1.5;
        padding-bottom: $section--content-padding * 1.5;

        @include media(xs) {
            padding-top: $section--content-padding / 2;
            padding-bottom: $section--content-padding / 2;
        }

    }

}
