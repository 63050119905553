.section--numbered-callouts {
    background: map-get($colors, gray-lighter);

    @include callouts;

    .callouts {
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: #fff;

        .callout {

            .number {
                font-family: $heading-font-family;
                font-size: 2rem;
                font-weight: $semibold;
                opacity: 0.5;
                color: #fff; 
            }   

        } // callout

    } // callouts

    .section-footer {
        margin-top: 0;
    }
    
}