// Global
// -------------------------

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }

}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

body {
  font-family: $base-font-family;
  line-height: 1.2;
}

em {
  font-style: italic;
}

strong {
  font-weight: $semibold;
}

.site {
  overflow: hidden;

  .site-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: stretch;

    .site-footer {
      margin-top: auto;
    }
    
  }

}

p {
  line-height: 1.4;
  margin: 1em 0;
}

a {
  font-weight: $bold;
  text-decoration-thickness: 0.2rem;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

blockquote {
  color: white;
}

svg {

  &.icon {
    width: 100%;
    height: auto;
  }

}

hr {
  background: map-get($colors, gray-light);
  height: 1px;
  border: 0;
  margin: 2rem 0;
}

input,
textarea,
button,
select {
  font-family: $base-font-family;
}

* {

  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }

}