// Containers
// -------------------------

.container {
    @include media(xs) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.container-fluid {
    @include media(xs) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}


.container-fluid,
.container-no-gutter {
    max-width: $site-max-width;
}

.container-no-gutter {
    margin-left: auto;
    margin-right: auto;    
}

.container-full-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

img.aligncenter { // wordpress wysiwyg
    margin-left: auto;
    margin-right: auto;
}

// Sections (blocks)
// -------------------------

.section {
    padding: #{$section--padding} 0;
    background-size: cover !important;
    background-position: center center;
    color: map-get($colors, gray-dark);

    @include media(sm) {
        padding: #{$section--padding * 2} 0; 
    }

    .section-header {
        margin-bottom: 2rem;

        .title {
            @include font-size(2.75rem);
        }

        .subtitle {
            @include font-size(1.8rem);
        }

        .title + .subtitle {
            margin-top: -1em;
        }

        p {
            @include font-size(1.5rem);

            a {
                color: map-get($colors, purple);
                @include link-underline();
            }
        }        
    }

    .section-footer {
        margin-top: 2rem;
        margin-bottom: 2rem;

        .buttons {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                margin: 0.35em;
            }
        }
        
        a:not(.button) {
            font-size: 1.25rem;
            @include link-underline();

            @include media(xs) {
                font-size: 1.1rem;
            }
        }
    }

    .section-content {
        // generic content styles
    }

    .links {        
    }

    .shadow {
        box-shadow: $shadow;
    }
    
    .row {

        &.row-no-gutter {
            margin-left: 0;
            margin-right: 0;

            [class*="col"] {
                padding-left: 0;
                padding-right: 0;
            }

        }

    }
    
}