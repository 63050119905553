@mixin author {

    .author {
        display: flex;
        align-items: center;

        .author-image {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: map-get($colors, yellow);
            margin-right: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 2.5rem;
                height: 2.5rem;
                color: map-get($colors, purple);
            }

        }

        .author-name {
            margin-bottom: 0.15em;
        }
    }

}