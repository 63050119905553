@import "../../parent/components/header";

// youth customizations

.site-header {

    .header__nav {
        font-family: $heading-font-family;
    }

}