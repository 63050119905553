// blocks/events.twig
.section--events {

    .search-form {
        margin-bottom: 2rem;
    }

    .event {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;
        overflow: hidden;

        .event-image {
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 100%;
            top: 0; left: 0;
            object-fit: cover;
            backface-visibility: hidden;
            transition: 0.5s $easeInOutCirc;
        }

        .event-details {
            z-index: 2;
            position: relative;
            
            .event-meta {
                display: flex;
                flex-wrap: wrap;
    
                .meta {
                    margin: 0.25em 1.25em 0.25em 0;
                    display: flex;
                    align-items: center;
                    line-height: 1;
    
                    .icon {
                        height: 1.5em;
                        width: 1.5em;
                        display: inline-block;
                        flex: 0 0 1.5em;
                        margin-right: 0.65em;
                    }
    
                }
    
            } // event-meta              
        }

        &.event--thumbnail {
            min-height: 15rem;
            margin-bottom: 1rem;
            height: calc(100% - 1rem);
            text-decoration: none;

            &:hover {

                .event-image {
                    transform: scale(1.03);
                }

            }

            .event-details {
                display: flex;
                flex-direction: column;
                background-color: rgba(#000000, 0.8);
                padding: 1.5rem;
    
                .event-title {
                    font-size: 1.25rem;
    
                    a {
                        text-decoration: none;
                    }
                }

            } // event-details
    
            .event-meta {
    
                .meta {
    
                    .icon {      
                        color: map-get($colors, red); // TODO: the icon isn't actually vectored                  
                    }
    
                }
    
            } // event-meta            

        } // thumbnail

        &.event--featured {
            position: relative;
            margin-bottom: 1rem;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -30%;
                right: 30%;
                background: linear-gradient(to right, rgba(darken(map-get($colors, red), 10%), 1) 30%, rgba(map-get($colors, red), 0.86) 100%);
                transform: skew(-16.5deg);
                z-index: 1;
                transition: 1s $easeInOutCirc;
            }

            @include media(sm) {
                &:before {
                    left: 0;
                    right: 0;
                    transform: none;
                }
            } 
            
            .event-image {
                transition: 1s $easeInOutCirc;
            }
            
            &:hover {

                .event-image {
                    transform: scale(1.03);
                }

                &:before {
                    transform: translate(-5%, 0) skew(-16.5deg);
    
                    @include media(sm) {
                        transform: none;
                    }
                }

            }

            .event-details {
                padding: 4rem;
                min-height: 60vh;
                width: 70%;
                padding-right: 15%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                @include media(sm) {
                    width: 100%;
                    padding: 2rem 1rem;
                }

                .event-title {
                    @include font-size(3rem);
                    margin-bottom: 0.5em;
                }

                .event-description {
                
                    p {
                        font-size: 1.2rem;
                        margin: 0.5em 0;
                        line-height: 1.35;
                    }

                }

                .event-meta {
                    margin: 1.5rem 0 2.5rem;
                    font-size: 1.35rem;
    
                    .meta {
                        
                        .icon {      
                            color: map-get($colors, purple-dark); // TODO: the icon isn't actually vectored                  
                        }
        
                    }
        
                } // event-meta   

            } // event-details

        } // featured

    } // event

    .tiny-slider-wrapper {
        position: relative;
    }

    .tiny-slider-previous, 
    .tiny-slider-next {
        position: absolute;
        display: block;
        height: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-content: center;
        align-items: center;
        width: 1.5rem;

        .icon {
            color: map-get($colors, gray);
        }

    }

    .tiny-slider-previous {
        left: 0;
        margin-left: -4rem;
        transform: scaleX(-1);
    }

    .tiny-slider-next {
        right: 0;
        margin-right: 4rem;
    }

}
