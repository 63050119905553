.section--challenges {
    position: relative;

    .challenges {
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;

        .challenge-wrapper {
            margin: 1rem;
        }

        .challenge {
            width: 100%;
            height: 0;
            padding-bottom: 100%; // aspect ratio 1:1
            border-radius: 50%; // circle
            color: white;
            display: block;
            box-shadow: $shadow;
            position: relative;
            transition: 0.45s $easeInOutCirc;
            backface-visibility: hidden;
            transform: scale(1.0001);

            .challenge-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 75%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                backface-visibility: hidden;

                .challenge-icon {
                    width: 50%;
                    padding-top: 50%;
                    margin-bottom: 1rem;
                    position: relative;
                    backface-visibility: hidden; 
    
                    svg {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0; left: 0;
                        fill: currentColor;
                        transition: 0.45s $easeInOutCirc;
                        backface-visibility: hidden;  
                        transform: scale(1.0001);                  
                    }
    
                }

                .challenge-label {
                    flex: 1;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    font-size: 1.25rem;
                    backface-visibility: hidden; 
                    transform: scale(1.0001);
                }
            }

            &:hover {
                transform: scale(1.04);
                box-shadow: $shadow-hover;

                .challenge-content .challenge-icon svg {
                    transform: scale(1.1);
                }
            }

        } // challenge
    }
}