.section--column {

    .columns {
  
      .column {
        padding: 1.5rem 2rem;
        margin: 1rem 0;
  
        .icon {
          width: $icon-small;
          margin: 0 auto 1rem;
  
          &.larger {
            width: $icon-default;
          }
  
        }
        
        .title {
          font-size: 1.5rem;
        }
  
        p {
          margin: 1rem 0 2rem;
        }
        
        .button {
  
        }
      }
    }
  }
  
  .columns--lines {
  
    .column {
      position: relative;
  
      &:after {
        display: block;
        position: absolute;
        top: 0; 
        bottom: 0;
        right: -0.5rem;
        content: '';
        border-right: 1px solid map-get($colors, purple-light);
    
        @include media(xs) {      
          border-right: none;
          border-bottom: 1px solid map-get($colors, purple-light);
        }
    
      }
  
    }
  
    [class*="col"]:last-of-type .column:after {
      border-right: none;
      border-bottom: none;
    }
    
  }
  