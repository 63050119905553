// blocks/cwsf/cwsf-hero.twig

.section--hero-event {
    @extend .section--hero;
  
    padding-bottom: 0;
  
    .hero-nav {
      position: absolute;
      bottom: 50px;
      width: 100%;
      display: block;
  
      @include media(xs) {
        display: none;
      }
  
    }
  }
  