.highlight {
    position: relative;
    padding-left: 0.8rem;
    // TODO: use pseudo mixin
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        border-right: 4px solid map-get($colors, red);
        height: 100%;
    }
    &.bottom {
        padding-left: 0;
        // TODO: use pseudo mixin
        &:before {
            position: absolute;
            left: 0;
            right: 0;
            border-bottom: 4px solid map-get($colors, red);
            width: 50%;
        }
    }
}
